<template>
  <div class="our-projects col-11 ml-auto px-0">
    <div
      class="projects__block"
      data-aos="fade-right"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="projects__content">
        <div class="projects__content__title">{{ title }}</div>
        <div class="projects__content__sub">
          {{ sub }}
        </div>
      </div>
      <div class="projects__detail">
        <div class="projects__detail__item">
          <div class="projects__detail__number" count="50">0</div>
          <div class="projects__detail__descripe">
            <span>++</span>
            <p>{{ clients }}</p>
          </div>
        </div>
        <div class="projects__detail__item">
          <div class="projects__detail__number" count="60">0</div>
          <div class="projects__detail__descripe">
            <span>++</span>
            <p>{{ companyProjects }}</p>
          </div>
        </div>
      </div>
      <router-link
        :to="'/projects'"
        style="text-decoration: none"
        class="btn-see-more-large d-flex"
      >
        <button
          type="button"
          class="btn btn-primary text see-all d-flex align-items-center btn-project-view text-nowrap"
        >
          {{ btnView }}
          <svg
            width="18"
            height="12"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="services__item__detail-img"
          >
            <path
              d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
              fill="white"
            />
          </svg>
        </button>
      </router-link>
    </div>
    <!-- <Carousel
      :data="list_project"
      data-aos="fade-left"
      data-aos-duration="500"
      data-aos-offset="300"
      :isLimitSlide="true"
    /> -->

    <Carousel2
      :data="list_project"
      data-aos="fade-left"
      data-aos-duration="500"
      data-aos-offset="300"
      :isLimitSlide="true"
    />
    <router-link
      :to="'/projects'"
      style="text-decoration: none; z-index: 15"
      class="btn-see-more d-none justify-content-center mt-3"
    >
      <button
        type="button"
        class="btn btn-primary text see-all d-flex align-items-center"
        style="
          background: #fc7735;
          border-radius: 40px;
          padding: 10px 28px;
          width: max-content;
          border: none;
          gap: 8px;
        "
      >
        {{ btnView }}
        <svg
          width="18"
          height="12"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="services__item__detail-img"
        >
          <path
            d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
            fill="white"
          />
        </svg>
      </button>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Carousel from '../Components/Carousel.vue';
import Carousel2 from '../Components/Carousel2.vue';
export default {
  name: 'our-pproject',
  data() {
    return {
      title: this.$t('home').project.title,
      sub: this.$t('home').project.sub,
      btnView: this.$t('home').project.btnView,
      companyProjects: this.$t('home').project.companyProjects,
      clients: this.$t('home').project.clients,
      list_project: this.$t('project_detail').listProjectDetails.slice(0, 12),
      urlVideo: ''
    };
  },
  watch: {
    lang() {
      this.title = this.$t('home').project.title;
      this.sub = this.$t('home').project.sub;
      this.btnView = this.$t('home').project.btnView;
      this.companyProjects = this.$t('home').project.companyProjects;
      this.clients = this.$t('home').project.clients;
      this.list_project = this.$t('project_detail').listProjectDetails.slice(
        0,
        12
      );
    }
  },
  mounted() {
    const counter1 = document.querySelectorAll('.projects__detail__number')[0];
    const counter2 = document.querySelectorAll('.projects__detail__number')[1];

    const speed = 999999999999;
    const animate1 = () => {
      const value = +counter1.getAttribute('count');
      const data = +counter1.innerText;
      const time = value / speed;
      if (data < value) {
        counter1.innerText = Math.ceil(data + time);
        setTimeout(animate1, 20);
      } else {
        counter1.innerText = value;
      }
    };
    const animate2 = () => {
      const value = +counter2.getAttribute('count');
      const data = +counter2.innerText;
      const time = value / speed;
      if (data < value) {
        counter2.innerText = Math.ceil(data + time);
        setTimeout(animate2, 20);
      } else {
        counter2.innerText = value;
      }
    };
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        //entry.isIntersecting là element đó có được scroll tới hay chưa
        if (entry.isIntersecting) {
          //load(entry.target);
          setTimeout(() => {
            animate1();
            animate2();
          }, 200);
        }
      });
    });
    observer.observe(counter1);
    // counters.forEach((counter) => {
    // });
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  components: {
    // Carousel,
    Carousel2
  }
};
</script>

<style lang="scss" scroped>
.our-projects {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;
  overflow: hidden;
  .projects__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 30%;
  }
  .projects__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .projects__content__title {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    color: var(--blue-text);
    z-index: 0;
    &::after {
      content: '';
      position: absolute;
      background: url('/images/bg/circle-title.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 38px;
      height: 38px;
      z-index: -1;
      top: 0;
      left: -18px;
    }
  }
  .projects__content__sub {
    color: var(--grey-text);
    font-weight: 500;
    font-size: 20px;
  }
  .projects__detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .projects__detail__item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .projects__detail__number {
    font-size: 70px;
    line-height: 1;
    font-weight: 700;
    color: var(--blue-text);
  }
  .projects__detail__descripe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      font-size: 44px;
      line-height: 1;
      font-weight: 700;
      color: var(--orange);
    }
    p {
      font-size: 24px;
      font-weight: 400;
      color: var(--grey-text);
      margin-bottom: 0;
    }
  }

  .btn-project-view {
    background: #fc7735;
    border-radius: 40px;
    padding: 10px 28px;
    width: max-content;
    border: none;
    gap: 8px;
  }

  .btn-project-view:hover {
    opacity: 0.8;
    transition: 0.3s;
  }

  @media (max-width: 1024px) {
    & {
      flex-direction: column;
      gap: 32px;
      max-width: 100%;
      margin: auto !important;
      padding-right: auto !important;
    }
    .projects__block {
      width: 83.33%;
    }
    .projects__content__title {
      text-align: center;
      font-size: 32px;
    }
    .projects__detail {
      flex-direction: row;
      justify-content: center;
      gap: 16;
    }
    .projects__detail__number {
      font-size: 60px;
    }
    .projects__detail__descripe {
      span {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    .btn-see-more-large {
      display: none !important;
    }
    .btn-see-more {
      display: flex !important;
    }
  }
}
</style>
